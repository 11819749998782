import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SimpleBar from "simplebar";
import { uaCheck, mq } from "../modules/utils";

// cssファイルのインポートはapp.js以外で行わない。
import "simplebar/dist/simplebar.css";
// eslint-disable-next-line import/no-unresolved
import "swiper/css";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/pagination";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/navigation";

import "../sass/app.scss"; // メインCSS

uaCheck();
gsap.registerPlugin(ScrollTrigger);

const g = {
  html: document.getElementsByTagName("html")[0],
  body: document.getElementsByTagName("body")[0],
  header: document.getElementsByTagName("header")[0],
  wh: window.screen.height,
};

/**
 * ロード時にurl?〇〇があればid〇〇にジャンプする。
 */
const jumpOnloadFunc = () => {
  const { href } = window.location;
  const target = document.getElementById(href.split("?")[1]);
  if (!target) return;

  const gap = mq("pc") ? 0 : 0;
  const rect = target.getBoundingClientRect().top;
  const offset = window.pageYOffset;
  const targetPos = rect + offset - gap;

  window.scrollTo({
    top: targetPos,
    behavior: "smooth",
  });
};

/**
 * ページ内遷移のアニメーションの関数
 * href="#id名"があるaタグをクリックするとidの要素にスクロールする。
 * a[data-correction="pc時補正値, sp時補正値"]
 */
const smoothScrollFn = () => {
  const gap = 0;
  const triggers = document.querySelectorAll('a[href^="#"]');

  for (let i = 0; i < triggers.length; i += 1) {
    triggers[i].addEventListener("click", (e) => {
      e.preventDefault();
      const { hash } = triggers[i];
      const target = document.getElementById(hash.replace("#", ""));
      const { correction } = e.currentTarget.dataset;
      const corrArray = typeof correction === "undefined" ? [0, 0] : correction.split(",").map(Number);
      const corr = mq("pc") ? corrArray[0] : corrArray[1];

      if (target) {
        const rect = target.getBoundingClientRect().top;
        const offset = window.pageYOffset;
        const targetPos = rect + offset - gap + corr;

        window.scrollTo({
          top: targetPos,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: gap,
          behavior: "smooth",
        });
      }
    });
  }
};

/**
 * スクロールダウンするとクラスが付き、スクロールアップするとクラスが外れる関数
 * @param {string} _targetID イベント発火タイミングの基準となる要素のID名。この要素の位置（縦方向）をスクロール量が越すと発火する。一意なクラス名かタグ もしくは ID名
 * @param {string} _target クラスがトグルする要素のクラス名
 * @param {string} _classname トグルするクラス名、デフォルトで’is-scrolled’
 * @returns
 */
const toggleClassByScrollFn = (_targetID, _target = _targetID, _classname = "is-scrolled") => {
  const trigger = document.querySelector(_targetID);
  if (!trigger) return;
  const gap = mq("sp") ? -40 : 0;
  const target = document.querySelectorAll(_target);
  const height = trigger.clientHeight + gap;

  let offset = 0;
  let lastPosition = 0;
  let ticking = false;

  const onScroll = () => {
    if (lastPosition < height) return;

    target.forEach((trg) => {
      if (lastPosition > offset) trg.classList.add(_classname);
      if (lastPosition < offset) trg.classList.remove(_classname);
    });
    offset = lastPosition;
  };

  const exec = () => {
    lastPosition = window.scrollY;
    if (!ticking) {
      requestAnimationFrame(() => {
        onScroll();
        ticking = false;
      });
      ticking = true;
    }
  };

  window.addEventListener("scroll", exec);
};

/**
 * 任意の要素をクリックすると、任意の要素にクラスがついたり取れたりする関数
 * @param {string} _trigger クリックの対象となる要素のクラス名
 * @param {dom | string} _target クラスがトグルする要素のクラス名 デフォルトで_triggerと同値
 * @param {function} _function クリック時についで実行する関数
 * @param {string} _classname トグルするクラス名、デフォルトで’is-active’
 */
const toggleClassByClickFn = (_trigger, _target = _trigger, _function, _classname = "is-active") => {
  const trigger = document.querySelectorAll(_trigger);
  if (trigger.length === 0) return;

  // eslint-disable-next-line no-console
  let exec = () => console.log("ターゲット要素は無効です");

  // ターゲットがdomであれば
  if (_target instanceof HTMLElement) exec = (t) => t.classList.toggle(_classname);

  // ターゲットがストリングであり、その要素があれば
  if (typeof _target === "string")
    exec = (t) => {
      const TARGET = document.querySelectorAll(t);
      if (TARGET.length === 0) return;
      TARGET.forEach((_t) => _t.classList.toggle(_classname));
    };

  // クリックイベント
  trigger.forEach((_trg) =>
    _trg.addEventListener("click", () => {
      exec(_target);
      if (_function) _function();
    })
  );
};

/**
 * スクロールバー
 */
const scrollbar = {
  init: (_this) => {
    const target = document.querySelectorAll(".js-sb");
    if (target.length === 0) return;

    const options = {
      autoHide: false,
      scrollbarMinSize: 200,
    };

    target.forEach((_target) => {
      const sb = () => new SimpleBar(_target, options);
      sb();
    });
  },
};

/**
 * アコーディオン
 * .js-acc-details[data-invalid="pcまたはsp"] => 値がpcの時、PC時このjs処理が無効となる、spの時もまた然り。
 */
const accordion = {
  datasets: {
    open: (_content, _details, _className) => {
      _details.classList.toggle(_className);
      _details.setAttribute("open", "true");
      return gsap.fromTo(
        _content,
        {
          height: 0,
          opacity: 0,
        },
        {
          height: "auto",
          opacity: 1,
          duration: 0.3,
          overwrite: true, // 既存(実行中)のトゥイーンを破棄し、このトゥイーンに上書きする
        }
      );
    },
    close: (_content, _details, _className) => {
      _details.classList.toggle(_className);
      return gsap.to(_content, {
        height: 0,
        opacity: 0,
        duration: 0.3,
        overwrite: true,
        onComplete: () => _details.removeAttribute("open"),
      });
    },
  },
  set: (_this) => {
    const details = document.querySelectorAll(".js-acc-details");
    if (details.length === 0) return;

    const IS_OPENED_CLASS = "is-opened";

    details.forEach((_details) => {
      const summary = _details.querySelector(".js-acc-summary");
      const content = _details.querySelector(".js-acc-content");
      const invalidDeviceFlag = _details.dataset.invalid; // 無効なデバイス

      // アコーディオン開閉クリックイベント
      const addEvent = () => {
        if (_details.hasAttribute("open")) _details.classList.add(IS_OPENED_CLASS);

        summary.addEventListener("click", (_event) => {
          _event.preventDefault();

          // is-openedクラスの有無で判定（detailsのopen属性の判定だと、アニメーション完了を待つ必要がありタイミング的に不安定になるため）
          if (_details.classList.contains(IS_OPENED_CLASS)) {
            // 閉じる
            _this.close(content, _details, IS_OPENED_CLASS).restart();
          } else {
            // 開く
            _this.open(content, _details, IS_OPENED_CLASS).restart();
          }
        });
      };

      // アコーディオン機能停止
      const stopEvent = () => {
        // アコーディオンを開いた状態にする
        _details.open = true;
        // イベントリスナーを停止する
        summary.addEventListener("click", (_event) => _event.preventDefault());
      };

      switch (invalidDeviceFlag) {
        case "sp":
          // デバイス無効判定データ属性がspであればpc時のみ反映
          if (mq("sp")) stopEvent();
          if (mq("pc")) addEvent();
          break;

        case "pc":
          // デバイス無効判定データ属性がpcであればsp時のみ反映
          if (mq("pc")) stopEvent();
          if (mq("sp")) addEvent();
          break;

        default:
          addEvent();
          break;
      }
    });
  },
};

/**
 * ヘッダー
 */
const header = {
  init: (_this) => {
    _this.switchMenu(_this);
    _this.switchSearchMenu(_this);
    toggleClassByScrollFn("#header", "body");
  },
  holdBody: () => {
    if (mq("pc")) return;
    const isShow = g.body.classList.contains("show-menu") || g.body.classList.contains("show-searchMenu");
    g.body.style.overflow = isShow ? "hidden" : "initial";
  },
  switchMenu: (_this) => {
    toggleClassByClickFn(".js-opener-menu", g.body, _this.holdBody, "show-menu");
    toggleClassByClickFn(".js-closer-menu", g.body, _this.holdBody, "show-menu");
  },
  switchSearchMenu: (_this) => {
    toggleClassByClickFn(".js-trigger-search", g.body, _this.holdBody, "show-searchMenu");
    toggleClassByClickFn(".js-closer-searchMenu", g.body, _this.holdBody, "show-searchMenu");
  },
};

/**
 *  最後までスクロールするとクラスがつく関数
 */
const bottomOutFn = () => {
  const target = g.body;
  if (!target) return;

  const CLASS_NAME = "is-bottomout";
  const GAP = 100;

  const threshold = g.body.getBoundingClientRect().height - window.innerHeight - GAP;

  let scrollValue;
  let ticking = false;

  const exe = () => {
    if (scrollValue >= Math.floor(threshold)) {
      target.classList.add(CLASS_NAME);
    } else {
      target.classList.remove(CLASS_NAME);
    }
  };

  const onScroll = () => {
    scrollValue = window.scrollY;
    if (!ticking) {
      requestAnimationFrame(() => {
        exe();
        ticking = false;
      });
      ticking = true;
    }
  };

  window.addEventListener("scroll", onScroll);
};

/**
 * 画像読み込み遅延
 * @returns
 */
const lazyLoadFn = () => {
  const targets = document.querySelectorAll(".js-ll");
  if (targets.length === 0) return;

  const options = {
    root: null,
    rootMargin: "0% 0% 0% 0%",
    threshold: 0,
  };

  const observer = new IntersectionObserver((entries) => {
    for (let i = 0; i < entries.length; i += 1) {
      if (entries[i].isIntersecting) {
        const { target } = entries[i];
        const img = target.querySelector("img");
        const { src } = img.dataset;
        img.src = src;
        target.classList.add("is-loaded");
        observer.unobserve(entries[i].target);
      }
    }
  }, options);

  for (let i = 0; i < targets.length; i += 1) {
    observer.observe(targets[i]);
  }
};

/**
 * 今年を.js-wtyのテキストに当てる
 * @returns
 */
const writeThisYearFn = () => {
  const targets = document.querySelectorAll(".js-wty");
  if (targets.length === 0) return;
  for (let i = 0; i < targets.length; i += 1) {
    const target = targets[i];

    const thisYear = () => {
      const now = new Date();
      return now.getFullYear(); // return number
    };

    target.innerHTML = String(thisYear());
  }
};

/**
 * パララックス（Y軸方向）
 * @param {string} _className 動かす対象のラッパーとなるクラス名、その要素の高さが動かす範囲となる。
 * js-(_className)-target が動かす対象。
 * js-(_className)[data-y="整数"] => 移動量
 * js-(_className)[data-invalid="pcまたはsp"] => 値がpcの時、PC時このjs処理が無効となる、spの時もまた然り。
 */
const parallaxFn = (_className) => {
  gsap.utils.toArray(_className).forEach((_root) => {
    const invalidDeviceFlag = _root.dataset.invalid; // 無効なデバイス

    const set = () => {
      const y = Number(_root.getAttribute("data-y")) || -100;
      const target = _root.querySelector(`${_className}-target`);

      if (_root.getAttribute("data-y")) target.style.height = `calc(100% + ${Math.abs(y)}px)`;
      if (y > 0) {
        target.style.top = "auto";
        target.style.bottom = "0";
      }

      gsap.to(target, {
        y,
        scrollTrigger: {
          trigger: _root,
          start: "top bottom",
          end: "bottom top",
          scrub: 0.5,
          // markers: true,
        },
      });
    };

    switch (invalidDeviceFlag) {
      case "sp":
        // デバイス無効判定データ属性がspであればpc時のみ反映
        if (mq("pc")) set();
        break;

      case "pc":
        // デバイス無効判定データ属性がpcであればsp時のみ反映
        if (mq("sp")) set();
        break;

      default:
        set();
        break;
    }
  });
};

/**
 * ポップアップ
 * セッションストレージ利用の期間非表示あり。
 * #js-mypopup[data-width="pcの値, spの値"]でポップアップのサイズをオーバライドできる
 * @returns none
 */
const popupFn = () => {
  const root = document.querySelector("#js-mypopup");
  if (!root) return;

  const SHOW_CLASS = "is-showed";
  const SESSION_CLOSE_KEY = "isClosed";

  const init = () => {
    // ロード時セッションストレージに非表示判定の値が...
    if (sessionStorage.getItem(SESSION_CLOSE_KEY)) {
      // ...入っていたら、非表示状態にする
      root.classList.remove(SHOW_CLASS);
    } else {
      // ...入っていなかったら、表示状態にする
      root.classList.add(SHOW_CLASS);
    }
  };
  init();

  // 閉じる処理
  const close = () => {
    root.classList.remove(SHOW_CLASS);
    sessionStorage.setItem(SESSION_CLOSE_KEY, true);
  };

  // クローズボタン作成
  const closer = document.createElement("button");
  closer.setAttribute("class", "mypopup-closer");
  closer.addEventListener("click", close);
  root.appendChild(closer);

  // 幅サイズ調整
  const { width } = root.dataset;
  const widthData = typeof width === "undefined" ? false : width.split(",").map(String); // [PC時幅, SP時幅]
  if (widthData) {
    // 属性がなければ処理なし
    const PC_WIDTH = widthData[0];
    const SP_WIDTH = typeof widthData[1] === "undefined" ? PC_WIDTH : widthData[1];
    root.style.width = mq("pc") ? PC_WIDTH : SP_WIDTH;
  }
};

/**
 * モーダル
 * @param {*} rootID - モーダルコンテンツのルートとなるID
 * @param {*} triggerSelector - モーダルを展開するセレクタ
 * @returns
 */
const modalFn = (rootID, triggerSelector) => {
  const root = document.getElementById(rootID);
  if (!root) return;

  const triggers = document.querySelectorAll(triggerSelector);
  const closers = root.querySelectorAll(".js-mymodal-close");
  const bg = root.querySelector(".js-mymodal-bg");
  const html = document.getElementsByTagName("html")[0];

  const ACTIVE_CLASS = "is-active";
  const SHOW_CLASS = "is-show";

  // htmlのスクロールの有効・無効を切り替え
  const switchHtmlHold = () => {
    const IS_ACTIVE = root.classList.contains(ACTIVE_CLASS);
    if (IS_ACTIVE) {
      // モーダルが展開していたら
      html.style.overflow = "hidden";
    } else {
      // モーダルが閉鎖すれば
      html.style.overflow = "initial";
    }
  };

  // モーダル展開
  const openModal = (self) => {
    self.preventDefault();
    const { target, valid } = self.currentTarget.dataset;

    const targetContent = () => {
      let content;
      if (typeof target === "undefined") {
        // .js-mymodal-trigger に data-target="(数字)"がなければ、一番最初の.js-mymodal-contentを返す。
        content = root.querySelector(".js-mymodal-content");
      } else {
        // .js-mymodal-trigger に data-target="(数字)" があれば、その数字に対応した.js-mymodal-contentを返す。
        content = root.querySelector(`.js-mymodal-content[data-number="${target}"]`);
      }
      return content;
    };

    switch (valid) {
      case "pc":
        if (mq("pc")) {
          root.classList.add(ACTIVE_CLASS);
          targetContent().classList.add(SHOW_CLASS);
          switchHtmlHold();
        }
        break;

      case "sp":
        if (mq("sp")) {
          root.classList.add(ACTIVE_CLASS);
          targetContent().classList.add(SHOW_CLASS);
          switchHtmlHold();
        }
        break;

      default:
        root.classList.add(ACTIVE_CLASS);
        targetContent().classList.add(SHOW_CLASS);
        switchHtmlHold();
        break;
    }
  };

  // モーダル閉鎖
  const closeModal = () => {
    root.classList.remove(ACTIVE_CLASS);
    root.querySelector(`.js-mymodal-content.${SHOW_CLASS}`).classList.remove(SHOW_CLASS);
    switchHtmlHold();
  };

  triggers.forEach((trigger) => trigger.addEventListener("click", openModal));
  closers.forEach((closer) => closer.addEventListener("click", closeModal));
  bg.addEventListener("click", closeModal);
};

document.addEventListener("DOMContentLoaded", () => {
  accordion.set(accordion.datasets);
  scrollbar.init(scrollbar.datasets);
  header.init(header);
  bottomOutFn();
  lazyLoadFn();
  smoothScrollFn();
  jumpOnloadFunc();
  writeThisYearFn();
  popupFn();
  modalFn("js-mymodal-utilty", ".js-mymodal-trigger-utilty");
  modalFn("js-mymodal", ".js-mymodal-trigger");
});

window.addEventListener("load", () => {
  parallaxFn(".js-para");
});
